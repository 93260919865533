import React, { useContext, useEffect, useRef, useState } from "react";

import { Link } from 'gatsby'
import { InView } from "react-intersection-observer";

import useIsInViewport from '../../custom/observer'
import { useAnimation, motion } from "framer-motion";

import { BtnArrow } from "../../custom/elements";
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'

const Branding = ({ data, mt0 }) => {

  const { language } = useI18next()

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)

  const [addInterval, setAddInterval] = useState(0)
  const [removeInterval, setRemoveInterval] = useState(0)
  const branding = useRef(null)
  const isInViewportBranding = useIsInViewport(branding);

  useEffect(() => {

    if (isInViewportBranding) {
      if (typeof document !== `undefined`) {
        document.querySelector('.container-branding-images-effect')?.classList.add('effect-hover');
      }
    }
    if (!isInViewportBranding) {
      if (typeof document !== `undefined`) {
        document.querySelector('.container-branding-images-effect')?.classList.remove('effect-hover');
      }
    }

  }, [isInViewportBranding]);


  const characterAnimation = {
    hidden: {
      opacity: 0,
      y: `5vh`,
    },
    visible: {
      opacity: 1,
      y: `0vh`,
      transition: {
        duration: 1,
        ease: [0.2, 0.65, 0.3, 0.9],
      },
    },
  };
  const ctrls = useAnimation();

  function updateTextAnimation(inView) {
    var i = 0
    if (typeof document !== "undefined") {
      var elementsToActivate = document.getElementsByClassName('link-hover-blue')

      if (inView) {
        ctrls.start("visible");

        setAddInterval(setInterval(addActiveClasses, 1000))
        setRemoveInterval(setInterval(removeActiveClasses, 2500))

        function addActiveClasses() {
          if (i < elementsToActivate.length)
            elementsToActivate[i].classList.add("active")
        }
        function removeActiveClasses() {
          if (i < elementsToActivate.length) {
            elementsToActivate[i].classList.remove("active")
            i = i + 1
          }

        }
      }
    }
    if (!inView) {
      ctrls.start("hidden");
      var toRemove = Array.from(elementsToActivate).filter(function (fruit) {
        if (fruit.classList.contains("active")) {
          return fruit
        } else return null
      })[0]

      if (toRemove)
        if (toRemove.classList?.contains("active")) toRemove.classList.remove("active")
      clearInterval(addInterval);
      clearInterval(removeInterval);
      i = 0
    }
  }

  return (
    <div className="container-margin  m-auto text-center">
      {data.title &&
        <div className="IExtraBold container-branding">
          <p className="ISemiBold fs-2 white uppercase" dangerouslySetInnerHTML={{ __html: data.title.first }} />
          <p className="ISemiBold fs-2 blue uppercase" dangerouslySetInnerHTML={{ __html: data.title.second }} />
        </div>
      }
      <Link to={translateUrl("/", language)} className={mt0 ? "container-branding-images-effect mt-0" : "container-branding-images-effect"} >
        {data.subtitle &&
          <div className="IExtraBold container-branding">
            <p ref={branding} className="title-white-border " dangerouslySetInnerHTML={{ __html: data.subtitle }} />
          </div>
        }
        <div className="container-branding-multiple-images">
          <div className="grid two-columns">
            <div className="p-relative m-right effect-border-main-container ">
              <p className="fs-5 ISemiBold blue uppercase" id="firstText">{data.multipleAnimation.texts[0]}</p>
              <div className="container-spin">
                <img className=" inside-img" id="firstImg" src="https://res.cloudinary.com/dersmcqwc/image/upload/v1665045717/images/first-bg_jif7pg.png" alt="BG-1" />
              </div>
            </div>
            <div className="p-relative m-left effect-border-main-container">
              <p className="fs-5 ISemiBold blue uppercase" id="secondText">{data.multipleAnimation.texts[1]}</p>
              <div className="container-spin">
                <img id="secondImg" className="m-left inside-img" src="https://res.cloudinary.com/dersmcqwc/image/upload/v1665045862/images/second-bg_xzcapl.png" alt="BG-2" />
              </div>
            </div>
          </div>
          <div className="p-relative effect-border-main-container" id="thirdContainer">
            <p className="fs-5 ISemiBold blue uppercase" id="thirdText">{data.multipleAnimation.texts[2]}</p>
            <div className="container-spin">
              <img id="thirdImg" className="m-auto inside-img" src="https://res.cloudinary.com/dersmcqwc/image/upload/v1664891600/images/third-bg_jd8dkr.png" alt="BG-3" />
            </div>
          </div>
        </div>
      </Link>

      {data.whatwedo &&
        <InView as="div" threshold={0.5}
          onChange={inView => {
            updateTextAnimation(inView)
          }}>
          <motion.div
            initial="hidden"
            animate={ctrls}
            variants={characterAnimation}
            className="container-branding-areas grid mt-5">
            <div className="container-text vertical-center">
              <p className="white fs-2 ISemiBold text-left uppercase" >
                {data.whatwedo.title.text.map((word, i) => (
                  <span key={'word' + i} className={data.whatwedo.title.blue.includes(word) ? 'blue' : ' '} > {word}</span>
                ))}
              </p>
              <p className="white fs-6 ILightBeta width-70 text-left  mt-2" >
                {data.whatwedo.details}
              </p>
            </div>
            <div className="container-areas vertical-center">
              {data.areas.map((item) => (
                <Link to={translateUrl(item.link, language)} className="d-inline-flex link-hover-blue vertical-center" key={'area' + item.text}>
                  <p className="mb-0 fs-5 ISemiBold uppercase hover-blue white text-left mr-1">{item.text}</p>
                  <BtnArrow />
                </Link>
              ))}
            </div>
          </motion.div>
        </InView>
      }
    </div>
  );
}
export default Branding;