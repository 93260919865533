import React, { useState } from "react";

import { InView } from "react-intersection-observer";

import Branding from "../homepage/branding";

const WorkComponent = ({ data }) => {


  var intersecting = [useState({
    isIntersecting: false,
    setIntersecting: false
  })];


  function update() {
    if (typeof document !== `undefined`) {

      if (intersecting.isIntersecting) {
        setTimeout(function () {
          document.querySelector('.container-branding-images-effect')?.classList.add('effect-hover');
          for (const box of document.querySelectorAll('.buttons')) {
            box.classList.add('buttons-hover');
          }
        }, 500);

      } else if (!intersecting.isIntersecting) {
        setTimeout(function () {
          document.querySelector('.container-branding-images-effect')?.classList.remove('effect-hover');
          for (const box of document.querySelectorAll('.buttons')) {
            box.classList.remove('buttons-hover');
          }
        }, -500);
      }
    }

  }

  return (
    <InView as="div" className="container-margin-small  m-auto text-center" threshold={0.6} onChange={inView => {
      intersecting.isIntersecting = inView
      intersecting.setIntersecting = inView
      update()
    }}>
      <div className="white IBold mt-4">
        <div className="m-auto">

          <section>
            <Branding data={data} mt0 />
          </section>
        </div>
      </div>
    </InView>
  );
}
export default WorkComponent;