import  { useEffect, useState, useMemo } from "react";

export default function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  var observer = useMemo(() => {
    if (typeof window !== "undefined")
      return new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsIntersecting(entry.isIntersecting)
          });
        },
        {
          rootMargin: '-30% 0% -35% 0%',
          threshold: 0
        }
      )
  }, []);

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref?.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [ref, observer]);

  return isIntersecting;
}