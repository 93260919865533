import * as React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import SmoothScroll from "../components/layout/smoothScroll";

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import Slogan from '../components/slogan3'
import Work from '../components/work/work'
import WorkMobile from '../components/work/workMobile'
import Services from '../components/work/services'
import ServicesMobile from '../components/work/servicesMobile'

import '../sass/work.scss';

const WorkPage = ({ location }) => {
  const breakpoints = useBreakpoint();

  const { t } = useTranslation()
  const dataGlobal = t("global", { returnObjects: true })
  const dataWork = t("work", { returnObjects: true })


  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={dataGlobal} page={dataWork.page}>
      <Seo seo={dataWork.seo} />

      {breakpoints.mobile ?
        <>
          <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1713192508/Videos/O_que_fazemos_mobile_wy0wwo.mp4"
            global={dataGlobal} textstatic={dataWork} mobile centered={true}
            location={location} />

          <SmoothScroll>

              <Slogan data={dataWork.firstsloganmobile} index={0} mobile />

              <WorkMobile data={dataWork.branding} />

              <Slogan data={dataWork.secondsloganmobile} index={1} mobile />

              <ServicesMobile data={dataWork} />


            <div className="container-scroll-heigth bg-black" />

          </SmoothScroll>
        </>
        :
        <>
          <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1713192509/Videos/O_que_fazemos_Desktop_tmvxal.mp4"
            global={dataGlobal} textstatic={dataWork} location={location} />

          <SmoothScroll>

                <Slogan data={dataWork.firstslogan} index={0} />

                <Work data={dataWork.branding} />

                <Slogan data={dataWork.secondslogan} index={1} />

                <Services data={dataWork} />


          </SmoothScroll>

        </>
      }
    </Layout>
  )
}

export default WorkPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "work"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`