import React, { useContext } from "react";
import { Link } from "gatsby";
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import { useI18next } from 'gatsby-plugin-react-i18next'
import Accordion from 'react-bootstrap/Accordion';
import BtnPlusFill from "../BtnPlusFill";

const ServicesMobileComponent = ({ data }) => {
  const { language } = useI18next()

  const {
    translateUrl // (url(, locale)) => translatedUrl
  } = useContext(TranslateUrlsContext)
  return (
    <div className="work-main-container container-margin-small">
      <Accordion defaultActiveKey={data.services[0].slug}>
        {data.services?.map((item, index) => (
          <Accordion.Item eventKey={item.slug} key={item.slug + index}>

            <Accordion.Header>
              <span className="white fs-3 ILightBeta">{item.title}</span>
            </Accordion.Header>
            <Accordion.Body>
              <p className="ILightBeta white fs-6 mb-4">{item.text}</p>
              {item.areas?.map((area, index2) => (
                <p className="ILightBeta white fs-6 uppercase lh-lg" key={area + index2}>{area}</p>
              ))}
              <Link to={translateUrl(item.btn.btnlink, language)} className=" mt-4 fs-6 ILightBeta mt-auto mb-0  d-grid b-0 ms-auto me-0 align-items-end">
                <div className="mt-4 d-flex align-middle bg-black pl-1 pr-1 round-corners-10 mb-0" >
                  <p className="uppercase white IBlack  fs-6 ILightBeta mb-0 me-2" >
                    {item.btn.btntext}
                  </p>
                  <BtnPlusFill classes="max-width-2" />
                </div>
              </Link>
             
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </div >)
}
export default ServicesMobileComponent;